import { invoicer } from "../data/invoicer";

export function MyAddress() {
    return (
        <div className="personal-data">
            <div className="data-block">
                <div className="bold">{invoicer.name}</div>
                <div className="street">{invoicer.address.street}</div>
                <div>{invoicer.address.town}</div>
            </div>
            <div className="data-block">
                <strong className="uid-number">UID: {invoicer.uid}</strong>
                <div className="mail">{invoicer.mail}</div>
            </div>
        </div>
    );
}
