import React, { ReactNode, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { IInvoiceItem, InvoiceItem } from "./InvoiceItem";
import { Summary } from "./Summary";

interface InvoiceItemsProps {
    onTotalSumChange: (sum: number) => void;
}

export function InvoiceItems(props: InvoiceItemsProps) {
    const [items, setItems] = useState<IInvoiceItem[]>([
        {
            id: uuidv4().substring(0, 7),
            text: "The work that has been done",
            sum: 100,
        },
    ]);

    function onAddItemClick(addAfter: string) {
        let index = 0;
        items.forEach((item: IInvoiceItem, key: number) => {
            if (item.id === addAfter) {
                index = key;
            }
        });

        const newItem: IInvoiceItem = {
            id: uuidv4().substring(0, 7),
            text: "The work that has been done",
            sum: 100,
        };

        setItems([
            ...items.slice(0, index + 1),
            newItem,
            ...items.slice(index + 1),
        ]);
    }

    function onRemoveItemClick(itemId: string) {
        if (items.length < 2) {
            return;
        }
        setItems((items) => {
            return items.filter((item) => item.id !== itemId);
        });
    }

    function onItemChange(item: IInvoiceItem) {
        setItems((items: IInvoiceItem[]): IInvoiceItem[] => {
            return items.map((editedItem: IInvoiceItem): IInvoiceItem => {
                if (editedItem.id === item.id) {
                    return item;
                }

                return editedItem;
            });
        });
    }

    function renderItems(): ReactNode[] {
        const result: ReactNode[] = [];

        items.forEach((item: IInvoiceItem) => {
            result.push(
                <InvoiceItem
                    key={item.id}
                    item={item}
                    onAddItemClick={onAddItemClick}
                    onRemoveItemClick={onRemoveItemClick}
                    onItemChange={onItemChange}
                />
            );
        });

        return result;
    }

    return (
        <>
            <div className="head-row">
                <div>Projektleistung</div>
                <div>Netto</div>
            </div>

            {renderItems()}

            <Summary items={items} onTotalSumChange={props.onTotalSumChange} />
        </>
    );
}
