import React, { useState } from "react";

import { Header } from "./Header";
import { Address } from "./Address";
import { InvoiceDate } from "./InvoiceDate";
import { Footer } from "./Footer";
import { InvoiceNumber } from "./InvoiceNumber";
import { Payment } from "./Payment";
import { InvoiceItems } from "./InvoiceItems";
import { invoicer } from "../data/invoicer";
import { PageNavigation, TPageProps } from "./Page";

export default function Outvoice(props: TPageProps) {
    const [invoiceNumber, setInvoiceNumber] = useState<string>(
        "0".repeat(invoicer.formats.invoiceNumber.serialNumber.numberOfDigits)
    );
    const [totalSum, setTotalSum] = useState<number>(0);

    function onInvoiceNumberChange(invoiceN: string) {
        setInvoiceNumber(invoiceN);
    }

    function onTotalSumChange(sum: number) {
        setTotalSum(sum);
    }

    return (
        <div className="wrap">
            <div className="invoice-paper">
                <Header />
                <Address invoiceNumber={invoiceNumber} />
                <InvoiceDate />
                <InvoiceNumber
                    invoiceNumber={invoiceNumber}
                    onChange={onInvoiceNumberChange}
                />
                <InvoiceItems onTotalSumChange={onTotalSumChange} />
                <Payment invoiceNumber={invoiceNumber} totalSum={totalSum} />
                <Footer
                    invoiceNumber={invoiceNumber}
                    pageNumber={props.pageNumber}
                    totalPageNumbers={props.totalPagesNumber}
                />
            </div>
            <PageNavigation {...props} />
        </div>
    );
}
