import React from "react";
import QRCode from "react-qr-code";
import { invoicer } from "../data/invoicer";

interface PaymentProps {
    invoiceNumber: string;
    totalSum: number;
}

export function Payment(props: PaymentProps) {
    function getQrData(): string {
        return (
            "BCD\n" +
            "001\n" +
            "1\n" +
            "SCT\n" +
            invoicer.bank.bic +
            "\n" +
            invoicer.name +
            "\n" +
            invoicer.bank.iban.replace(" ", "") +
            "\n" +
            "EUR" +
            props.totalSum.toString() +
            "\n" +
            "\n" +
            "\n" +
            "Rechnung " +
            new Date().getFullYear() +
            "/" +
            ("0" + (new Date().getMonth() + 1)).slice(-2) +
            invoicer.formats.invoiceNumber.serialNumber.separator +
            props.invoiceNumber +
            "\n"
        );
    }

    return (
        <>
            <div>
                Ich ersuche Sie höflich, den oben angeführten Betrag auf mein
                Bankkonto ({invoicer.bank.name}) zu überweisen.
            </div>
            <div className="payment">
                <div>
                    <div className="payment-iban">
                        <div className="qr-code">
                            <QRCode
                                size={80}
                                value={getQrData()}
                                viewBox={`0 0 80 80`}
                            />
                        </div>
                        <div className="payment-message">
                            <p className="message-text">
                                Überweisung per QR-Code oder IBAN:
                            </p>
                            <strong>{invoicer.bank.iban}</strong>
                        </div>
                    </div>

                    <div className="payment-text">{invoicer.formats.salutations.appreciation}</div>
                    <div className="payment-text">{invoicer.formats.salutations.dismissal}</div>
                    <div className="payment-text">{invoicer.name}</div>
                </div>
            </div>
        </>
    );
}
