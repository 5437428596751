export function InvoiceDate() {
    const date = new Date();

    return (
        <div className="date-wrapper">
            <div>
                <span>Wien, am </span>
                <span contentEditable="true" className="flex-date">
                    {("0" + (new Date().getDate())).slice(-2)}.
                    {("0" + (new Date().getMonth() + 1)).slice(-2)}.
                    {date.getFullYear()}
                </span>
            </div>
        </div>
    );
}