interface TitleProps {
    invoiceNumber: string;
    companyShortName: string;
}

const Title = (props: TitleProps) => {
    return (
        <title>
            {("0" + (new Date().getMonth() + 1)).slice(-2)}-
            {props.invoiceNumber}-{props.companyShortName}-rechnung-
            {new Date().getFullYear().toString()}
        </title>
    );
};

export default Title;
