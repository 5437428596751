import { invoicer } from "../data/invoicer";

interface FooterProps {
    invoiceNumber?: string;
    pageNumber?: number;
    totalPageNumbers?: number;
}

export function Footer(props: FooterProps) {
    return (
        <div className="foot-note">
            <div className="invoice-number-clone" id="mirror-invoice-number">
                {props?.invoiceNumber && (
                    <>
                        <span className="invoice-title">Rechnungsnummer: </span>
                        <span className="current-year invoice-number-year">
                            {new Date().getFullYear().toString()}
                        </span>
                        <span>/</span>
                        <span>
                            {("0" + (new Date().getMonth() + 1)).slice(-2)}
                        </span>
                        <span>
                            {
                                invoicer.formats.invoiceNumber.serialNumber
                                    .separator
                            }
                        </span>
                        <span className="running-invoice-number">
                            {props?.invoiceNumber}
                        </span>
                    </>
                )}
            </div>
            <div className="page-number">
                <strong>{props.pageNumber}</strong>
                {invoicer.formats.footer.numberOfPages.separator}
                <strong>{props.totalPageNumbers}</strong>
            </div>
        </div>
    );
}
