import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Header } from "./Header";
import { Footer } from "./Footer";
import Outvoice from "./Outvoice";

export enum TPageType {
    Outvoice = "Outvoice",
    Letter = "Letter",
}

export type TPage = {
    id: string;
    pageNumber: number;
    type: TPageType;
};

export type TPageProps = {
    page: TPage;
    onAddItemClick: (addAfter: string, pageType: TPageType) => void;
    onRemoveItemClick: (addAfter: string) => void;
    onItemChange: (item: TPage) => void;
    pageNumber: number;
    totalPagesNumber: number;
};

export const PageNavigation = (props: TPageProps) => {
    return (
        <div className="page-buttons">
            <button
                className="leaf-button"
                onClick={() =>
                    props.onAddItemClick(props.page.id, TPageType.Outvoice)
                }
            >
                Outvoice
            </button>
            <button
                className="leaf-button"
                onClick={() =>
                    props.onAddItemClick(props.page.id, TPageType.Letter)
                }
            >
                Letter
            </button>
            <button
                className="leaf-button"
                onClick={() => props.onRemoveItemClick(props.page.id)}
            >
                -
            </button>
        </div>
    );
};

export default function Page(props: TPageProps) {
    if (props.page.type === TPageType.Outvoice) {
        return (
            <>
                <Outvoice {...props} />
            </>
        );
    }
    return (
        <>
            <div className="wrap">
                <div className="invoice-paper">
                    <Header />
                    <Footer
                        pageNumber={props.pageNumber}
                        totalPageNumbers={props.totalPagesNumber}
                    />
                </div>
                <PageNavigation {...props} />
            </div>
        </>
    );
}
