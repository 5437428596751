import React, { useState, FocusEvent } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import classNames from "classnames";

export interface IInvoiceItem {
    id: string;
    text: string;
    sum: number;
}

interface InvoiceItemProps {
    item: IInvoiceItem;
    onAddItemClick: (addAfter: string) => void;
    onRemoveItemClick: (addAfter: string) => void;
    onItemChange: (item: IInvoiceItem) => void;
}

export function InvoiceItem(props: InvoiceItemProps) {
    const [sum, setSum] = useState<string>("100");
    const [text, setText] = useState<string>("The work that has been done");
    const [isError, setIsError] = useState<boolean>(false);

    function onItemTextChange(event: ContentEditableEvent) {
        setText(event.target.value || "");

        props.onItemChange({
            id: props.item.id,
            text: event.target.value || "",
            sum: Number(sum),
        });
    }

    function onItemSumChange(event: ContentEditableEvent) {
        if (Number.isNaN(Number(event.target.value.replace(",", ".")))) {
            setIsError(true);
        } else {
            setIsError(false);
        }

        setSum(() => event.target.value);
    }

    function onAddItemClick() {
        return function () {
            props.onAddItemClick(props.item.id);
        };
    }

    function onRemoveItemClick() {
        return () => {
            props.onRemoveItemClick(props.item.id);
        };
    }

    function onBlur(event: FocusEvent) {
        if (isError) {
            return;
        }

        props.onItemChange({
            id: props.item.id,
            text,
            sum: Number(event.target.innerHTML.replace(",", ".") || "0"),
        });
    }

    return (
        <div className="service-row multiply-row">
            <ContentEditable html={text} onChange={onItemTextChange} />

            <div className="service-cost currency">
                <ContentEditable
                    className={classNames({ "is-error": isError })}
                    html={sum}
                    onChange={onItemSumChange}
                    onBlur={onBlur}
                />
                <div>&nbsp;€</div>
            </div>

            <div className="row-buttons">
                <button className="row-button" onClick={onAddItemClick()}>
                    +
                </button>
                <button className="row-button" onClick={onRemoveItemClick()}>
                    -
                </button>
            </div>
        </div>
    );
}
