import exp from "constants";

var data: IInvoicer;
try {
    data = require("./invoicer.json");
} catch (err) {
    data = require("./invoicer.example.json");
}

export interface IInvoicer {
    name: string;
    mail: string;
    websiteLink: IWebsiteLink;
    companyName: string;
    fields: string[];
    address: IAddress;
    bank: IBank;
    uid: string;
    formats: IFormats;
}

export interface IAddress {
    street: string;
    town: string;
}

export interface IBank {
    name: string;
    bic: string;
    iban: string;
}

export interface IWebsiteLink {
    target: string;
    text: string;
}

export interface IFormats {
    invoiceNumber: IInvoiceNumber;
    footer: IFooter;
    salutations: ISalutations;
    general: IGeneral;
}

export interface IGeneral {
    fonts: IFonts;
}

export interface IFonts {
    family: string;
}

export interface ISalutations {
    dismissal: string;
    appreciation: string;
}

export interface IInvoiceNumber {
    serialNumber: ISerialNumber;
}

export interface ISerialNumber {
    separator: string;
    numberOfDigits: number;
}

export interface IFooter {
    numberOfPages: INumberOfPages;
}

export interface INumberOfPages {
    separator: string;
}

export const invoicer: IInvoicer = data;
