import { ChangeEvent, ReactNode, useState } from "react";
import { Helmet } from "react-helmet";

import { MyAddress } from "./MyAddress";

import { companies, ICompany } from "../data/companies";
import Title from "./Title";

interface AddressProps {
    invoiceNumber: string;
}

export function Address(props: AddressProps) {
    const [company, setCompany] = useState<ICompany>({
        name: "Company Name",
        uid: "UIDUIDUID",
        shortName: "",
        address: "Street Name",
        secondAddressLine: "",
        postalCode: "Postal Code",
        city: "Town & Country",
    });

    function onChange(event: ChangeEvent<HTMLSelectElement>) {
        const newCompany = companies.find(
            (company: ICompany) => company.shortName === event.target.value
        );

        if (newCompany) {
            setCompany(newCompany);
        }
    }

    function renderOptions(): ReactNode[] {
        const result: ReactNode[] = [];
        companies.forEach((company: ICompany) => {
            result.push(
                <option value={company.shortName} key={company.shortName}>
                    {company.shortName}
                </option>
            );
        });

        return result;
    }

    return (
        <>
            <Helmet>
                <Title
                    invoiceNumber={props.invoiceNumber}
                    companyShortName={company.shortName}
                />
            </Helmet>
            <div className="address-wrapper">
                <div className="address-customer">
                    <div
                        className="custom-select address-dropdown"
                        id="sel-address-select-dropdown"
                    >
                        <select onChange={onChange}>{renderOptions()}</select>
                    </div>

                    {company.name && (
                        <div contentEditable className="bold">
                            {company.name}
                        </div>
                    )}
                    {company.uid && (
                        <div contentEditable className="bold">
                            {company.uid}
                        </div>
                    )}
                    {company.address && (
                        <div contentEditable className="street">
                            {company.address}
                        </div>
                    )}
                    {company.secondAddressLine && (
                        <div contentEditable className="street">
                            {company.secondAddressLine}
                        </div>
                    )}
                    {company.postalCode && (
                        <div contentEditable className="postal-city">
                            {company.postalCode} {company.city}
                        </div>
                    )}
                </div>

                <MyAddress />
            </div>
        </>
    );
}
