import React, { useState } from "react";

import "./App.css";
import { invoicer } from "../data/invoicer";
import { Pages } from "./Pages";

export default function App() {
    return (
        <div style={{ fontFamily: invoicer.formats.general.fonts.family }}>
            <Pages />
        </div>
    );
}
