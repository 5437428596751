var data: ICompany[];
try {
    data = require("./companies.json");
} catch (err) {
    data = require("./companies.example.json");
}

export interface ICompany {
    name: string;
    uid: string;
    shortName: string;
    address: string;
    secondAddressLine: string;
    postalCode: string;
    city: string;
}

export const companies: ICompany[] = data;
