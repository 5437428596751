import { invoicer } from "./invoicer";

var logo: any;
try {
    logo = (
        <img src={require("./logo.svg").default} alt={invoicer.companyName} />
    );
} catch (err) {
    logo = <div className="logo">{invoicer.companyName}</div>;
}

export const Logo = () => {
    return logo;
};
