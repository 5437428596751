import {IInvoiceItem} from "./InvoiceItem";
import React, {useEffect, useState} from "react";

interface SummaryProps {
    items: IInvoiceItem[];
    onTotalSumChange: (sum: number) => void;
}

export function Summary(props: SummaryProps) {
    const [sum, setSum] = useState<number>(0);

    useEffect(() => {
        let resultSum: number = 0;
        props.items.forEach((item: IInvoiceItem) => {
            resultSum += item.sum;
        });
        setSum(resultSum);
        props.onTotalSumChange(resultSum * 1.2);
    }, [props]);

    return (
        <div className="result-wrapper">
            <div className="result-row">
                <div className="description">Nettobetrag</div>
                <div className="shadow-costs reserve-costs currency">
                    {(sum / 3).toFixed(2).replace('.', ',')}
                </div>
                <div className="net-cost">
                    <strong className="currency cost-minus-net">
                        {sum.toFixed(2).replace('.', ',')}
                        <span>&nbsp;€</span>
                    </strong>
                </div>
            </div>

            <div className="result-row net-cost">
                <div className="description">zzgl. Umsatzsteuer</div>
                <div className="cost-net currency">
                    {(sum * 0.20).toFixed(2).replace('.', ',')}
                    <span>&nbsp;€</span>
                </div>
            </div>
            <div className="result-row">
                <div className="description bold">Gesamtbetrag</div>
                <div className="shadow-costs total-keep currency">
                    {(sum / 3 + sum * 0.2).toFixed(2).replace('.', ',')}
                </div>
                <div className="net-cost">
                    <strong className="currency total-costs">
                        {(sum * 1.20).toFixed(2).replace('.', ',')}
                        <span>&nbsp;€</span>
                    </strong>
                </div>
            </div>

        </div>
    );
}